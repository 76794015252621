import { createStore } from "vuex";
import http from "@/http/config";
import {resetRoute} from '@/router'

interface State {
  token: string;
  role: string | number;
  userInfo: {
    balance?: number;
    nickName?: string;
    remindedChangePassword?: number;
    tel?: string;
    userId?: number;
    userName?: string;
    auth?: string;
    csShowMoney?: number;
    timeFlowCardShow?: number
  };
  boxNum: number,
  routerList: any[]
}

export default createStore<State>({
  state: {
    token: sessionStorage.token || "",
    role: sessionStorage.cs_role || 0,
    userInfo: JSON.parse(localStorage.getItem('cs_userInfo')) || {},
    boxNum: parseInt(sessionStorage.boxNum) || 0,
    routerList: []
  },
  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
    SET_USER_INFO(state, payload) {
      state.userInfo = payload;
    },
    SET_ROLE(state, payload) {
      state.role = payload
      sessionStorage.setItem('cs_role', payload)
    },
    LOG_OUT(state) {
      sessionStorage.clear();
      state.token = "";
      state.userInfo = {};
      localStorage.removeItem('cs_userInfo')
      resetRoute()
    },
    SET_REMIND_PASSWORD(state) {
      state.userInfo.remindedChangePassword = 1;
    },
    SET_BOX_NUM(state, payload) {
      state.boxNum = payload
    },
    SET_ROUTER_LIST(state, list) {
      state.routerList = list
    }
  },
  actions: {
    GET_USER_INFO({ commit }) {
      return new Promise((resolve, reject) => {
        http
          .getUserInfo()
          .then((res) => {
            if (res.status === 200) {
              res.data.auth = 'hotel' // 账号权限
              commit("SET_USER_INFO", res.data);
              window.localStorage.setItem('cs_userInfo',JSON.stringify(res.data))
              resolve(res.data);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    LOG_OUT({ commit }) {
      http.logout().then((res) => {
        if (res.status === 200) {
          commit("LOG_OUT");
        }
      });
    },
  },
  modules: {},
});
