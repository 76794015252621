import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "../store";
/**
 * 静态路由
 */
const staticRoutes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/login/index.vue"),
  },
  {
    path: "/404",
    component: () => import("../views/error-page/404.vue"),
    name: "Page404",
    meta: { title: "404" },
  },
  {
    path: "/root", // 路由分发页
    component: () => import("../views/hotelView/root/index.vue"),
  },
  {
    path: "/",
    redirect: "/root",
  },
];

/**
 * 动态路由meta对象参数说明
 * meta: {
 *      title:          名称
 *      isHide：        是否隐藏此路由
 *      isKeepAlive：   是否缓存组件状态
 *      auth：          当前路由权限标识 hotel:酒店 store:门店
 *      showMoney:      cs是否显示金额  true 显示  false:隐藏
 *      timeFlowCardShow 是否显示月卡 1是 0 否
 *      icon：          图标
 * }
 */

// 酒店路由
const hotelDynamicRoutes: Array<RouteRecordRaw> = [
  {
    path: "/rooms",
    redirect: '/roomsManagement',
    name: "Rooms",
    component: () =>
      import(/* webpackChunkName: "Layout" */ "../views/Layout.vue"),
    meta: { icon: "el-icon-s-data" },
    children: [
      {
        path: "/roomsManagement",
        name: "RoomsManagement",
        meta: { title: "客房管理", roles: [0, 1] },
        component: () => import("@/views/hotelView/roomsManagement/index.vue"),
      },
    ],
  },
  {
    path: "/hotelBill",
    redirect: '/hotelBill/list',
    name: "HotelBill",
    component: () =>
      import(/* webpackChunkName: "Layout" */ "../views/Layout.vue"),
    meta: { icon: "el-icon-s-data" },
    children: [
      {
        path: "list",
        name: "HotelBillLis",
        meta: { title: "业务对账单", roles: [0, 2] },
        component: () => import("@/views/hotelView/hotelBill/index.vue"),
      },
    ],
  },
  {
    path: "/hotelPermission",
    redirect: '/hotelPermission/hotelPermissionManage',
    name: "HotelPermission",
    component: () =>
      import(/* webpackChunkName: "Layout" */ "../views/Layout.vue"),
    meta: { icon: "el-icon-s-data" },
    children: [
      {
        path: "hotelPermissionManage",
        name: "HotelPermissionManage",
        meta: { title: "权限设置", roles: [0] },
        component: () => import("@/views/hotelView/permission/index.vue"),
      },
    ],
  },
];

// 门店路由
const storeDynamicRoutes: Array<RouteRecordRaw> = [
  {
    path: "/home",
    redirect: "/homeChart",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "Layout" */ "../views/Layout.vue"),
    meta: { auth: ["store"], icon: "el-icon-s-data" },
    children: [
      {
        path: "/homeChart",
        name: "HomeChart",
        meta: { title: "首页" },
        component: () =>
          import(
            /* webpackChunkName: "BillsChart" */ "../views/homeChart/index.vue"
          ),
      },
    ],
  },
  {
    path: "/usage",
    name: "Usage",
    component: () =>
      import(/* webpackChunkName: "Layout" */ "../views/Layout.vue"),
    meta: {
      title: "使用记录",
      auth: ["store"],
      icon: "el-icon-finished",
      showMoney: false,
    },
    children: [
      {
        path: "usageRecord",
        name: "UsageRecord",
        meta: { title: "使用记录" },
        component: () =>
          import(
            /* webpackChunkName: "BillsChart" */ "../views/usageRecord/index.vue"
          ),
      },
    ],
  },
  {
    path: "/bills",
    name: "Bills",
    component: () =>
      import(/* webpackChunkName: "Layout" */ "../views/Layout.vue"),
    meta: {
      title: "消费记录",
      auth: ["store"],
      icon: "el-icon-finished",
      showMoney: true,
    },
    children: [
      {
        path: "billsChart",
        name: "BillsChart",
        meta: { title: "费用账单" },
        component: () =>
          import(
            /* webpackChunkName: "BillsChart" */ "../views/bills/dataChart/index.vue"
          ),
      },
      {
        path: "timingCharge",
        name: "TimingCharge",
        meta: { title: "计时收费明细" },
        component: () =>
          import(
            /* webpackChunkName: "TimingCharge" */ "../views/bills/timingCharge/index.vue"
          ),
      },
      {
        path: "monthCharge",
        name: "MonthCharge",
        meta: { title: "网络服务费明细" },
        component: () =>
          import(
            /* webpackChunkName: "MonthCharge" */ "../views/bills/monthCharge/index.vue"
          ),
      },
    ],
  },
  {
    path: "/cards",
    name: "Cards",
    component: () =>
      import(/* webpackChunkName: "Layout" */ "../views/Layout.vue"),
    meta: {
      title: "月卡服务",
      auth: ["store"],
      icon: "el-icon-bank-card",
      timeFlowCardShow: 1,
    },
    children: [
      {
        path: "durationCard",
        name: "DurationCard",
        meta: { title: "月卡" },
        component: () =>
          import(
            /* webpackChunkName: "DurationCard" */ "../views/cards/durationCard/index.vue"
          ),
      },
      {
        path: "myDurationCard",
        name: "MyDurationCard",
        meta: { title: "我的月卡" },
        component: () =>
          import(
            /* webpackChunkName: "MyDurationCard" */ "../views/cards/myDurationCard/index.vue"
          ),
      },
    ],
  },
  {
    path: "/account",
    name: "Account",
    component: () =>
      import(/* webpackChunkName: "Layout" */ "../views/Layout.vue"),
    meta: { title: "账户管理", icon: "el-icon-s-custom" },
    children: [
      {
        path: "fundRecharge",
        name: "FundRecharge",
        meta: { title: "充值" },
        component: () =>
          import(
            /* webpackChunkName: "FundRecharge" */ "../views/account/recharge/index.vue"
          ),
      },
      {
        path: "fundRechargeRecord",
        name: "FundRechargeRecord",
        meta: { title: "充值记录" },
        component: () =>
          import(
            /* webpackChunkName: "FundRechargeRecord" */ "../views/account/rechargeRecord/index.vue"
          ),
      },
    ],
  },
  {
    path: "/invoice",
    name: "Invoice",
    component: () =>
      import(/* webpackChunkName: "Layout" */ "../views/Layout.vue"),
    meta: {
      title: "发票管理",
      icon: "el-icon-s-ticket",
      showMoney: true,
    },
    children: [
      {
        path: "applyInvoice",
        name: "ApplyInvoice",
        meta: { title: "申请发票" },
        component: () =>
          import(
            /* webpackChunkName: "ApplyInvoice" */ "../views/funds/applyInvoice/index.vue"
          ),
      },
      {
        path: "invoiceInfo",
        name: "InvoiceInfo",
        meta: { title: "发票信息管理" },
        component: () =>
          import(
            /* webpackChunkName: "InvoiceInfo" */ "../views/funds/invoiceInfo/index.vue"
          ),
      },
      {
        path: "invoiceRecord",
        name: "InvoiceRecord",
        meta: { title: "开票记录" },
        component: () =>
          import(
            /* webpackChunkName: "InvoiceRecord" */ "../views/funds/invoiceRecord/index.vue"
          ),
      },
    ],
  },
  {
    path: "/equip",
    name: "Equip",
    component: () =>
      import(/* webpackChunkName: "Layout" */ "../views/Layout.vue"),
    meta: { title: "设备管理", icon: "el-icon-receiving" },
    children: [
      {
        path: "equipManage",
        name: "EquipManage",
        meta: { title: "设备列表" },
        component: () =>
          import(
            /* webpackChunkName: "EquipManage" */ "../views/equipment/equipmentManage/index.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: staticRoutes,
});

const getUrlParam = function(name) {
  // 获取url参数
  const reg = new RegExp("(^|&?)" + name + "=([^&]*)(&|$)", "i");
  const r = window.location.href.substr(1).match(reg);
  if (r != null) {
    return decodeURI(r[2]);
  }
  return undefined;
};

/**
 * 定义404界面
 */
const pathMatch = {
  path: "/:path(.*)*",
  redirect: "/404",
};

// 获取路由
export function setFilterRouteEnd() {
  let setFilterRouteEnd = [];
  // 如果权限为门店 则需要判断是否显示金额， 是否显示月卡   firmType 0 门店  1酒店
  if (sessionStorage.getItem("firmType") === "0") {
    setFilterRouteEnd = storeDynamicRoutes;
    if (store.state.userInfo.csShowMoney === 1) {
      // 显示金额
      setFilterRouteEnd = setFilterRouteEnd.filter(
        (item) =>
          item.meta.showMoney === true || item.meta.showMoney === undefined
      );
    } else {
      // 隐藏金额
      setFilterRouteEnd = setFilterRouteEnd.filter(
        (item) =>
          item.meta.showMoney === false || item.meta.showMoney === undefined
      );
    }
    if (store.state.userInfo.timeFlowCardShow === 1) {
      // 显示月卡
      setFilterRouteEnd = setFilterRouteEnd.filter(
        (item) =>
          item.meta.timeFlowCardShow === 1 ||
          item.meta.timeFlowCardShow === undefined
      );
    } else {
      setFilterRouteEnd = setFilterRouteEnd.filter(
        (item) => item.meta.timeFlowCardShow === undefined
      );
    }
  } else {
    // setFilterRouteEnd = hotelDynamicRoutes;
    const roleId = store.state.role || 0
    setFilterRouteEnd = filterAsyncRoutes(hotelDynamicRoutes, Number(roleId))
  }
  return setFilterRouteEnd;
}

/**
 * 判断用户是否有权限访问单个路由
 * roles：用户角色
 * route：访问的路由
 */
const hasPermission = (roles: number, route: any) => {
  if (route.meta && route.meta.roles) {
    if (route.meta?.roles !== undefined) {
      return route.meta.roles.includes(roles);
    } else {
      return false;
    }
  } else {
    return true;
  }
};

/**
 * 筛选可访问的动态路由
 * roles：用户角色
 * route：访问的动态列表
 */
const filterAsyncRoutes = (routes: RouteRecordRaw[], roles: number) => {
  const res: RouteRecordRaw[] = [];
  routes.forEach((route) => {
    const r = { ...route };
    if (hasPermission(roles, r)) {
      if (r.children) {
        r.children = filterAsyncRoutes(r.children, roles);
      }
      res.push(r);
    }
  });
  return res;
};

/**
 * 添加动态路由
 * @method router.addRoute
 * @link 参考：https://next.router.vuejs.org/zh/api/#addroute
 */
export function setAddRoute() {
  let routers = setFilterRouteEnd();
  routers = [...routers, { ...pathMatch }];
  routers.forEach((route: RouteRecordRaw) => {
    const routeName: any = route.name;
    if (!router.hasRoute(routeName)) router.addRoute(route);
  });
  store.commit("SET_ROUTER_LIST", routers);
}

/**
 * 删除/重置路由
 * @method router.removeRoute
 * @link 参考：https://next.router.vuejs.org/zh/api/#push
 */
export function resetRoute() {
  setFilterRouteEnd().forEach((route: RouteRecordRaw) => {
    const routeName: any = route.name;
    router.hasRoute(routeName) && router.removeRoute(routeName);
  });
}

/**
 * 前端控制路由：初始化方法，防止刷新时路由丢失
 */
export async function initFrontEndControlRoutes() {
  const token = sessionStorage.token;
  // 无 token 停止执行下一步
  if (!token) return false;
  store.dispatch("GET_USER_INFO");
  // 添加动态路由
  await setAddRoute();
}
initFrontEndControlRoutes();

router.beforeEach(async (to, from, next) => {
  const token = sessionStorage.token;
  if (to.path === "/login") {
    if (getUrlParam("token")) {
      window.sessionStorage.token = getUrlParam("token");
      try {
        const res = await store.dispatch("GET_USER_INFO");
        if (res.userId) {
          next("/");
        } else {
          next();
        }
      } catch (error) {
        next();
      }
    } else {
      next();
    }
  } else {
    if (token) {
      const userName = store.state.userInfo.userName;
      if (userName) {
        next();
      } else {
        try {
          // get user info
          await store.dispatch("GET_USER_INFO");
          next();
        } catch (error) {
          // remove token and go to login page to re-login
          store.commit("LOG_OUT");
          resetRoute();
          next(`/login`);
        }
      }
    } else {
      resetRoute();
      next("/login");
    }
  }
});

export default router;
