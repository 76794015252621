const baseurl = process.env.VUE_APP_API_URL;

export default {

  // 客房管理列表
  hotelRoom: {
    method: "get",
    url: `${baseurl}/api/hotel-room`,
  },
  // 入住
  hotelCheckIn: {
    method: "post",
    url: `${baseurl}/api/hotel-room/check-in`,
  },
  // 客房字典
  hotelRoomDict: {
    method: "get",
    url: `${baseurl}/api/hotel-room/dict`,
  },
  // 换房
  hotelCheckChange: {
    method: "post",
    url: `${baseurl}/api/hotel-room/change-room`,
  },
  // 结束
  hotelCheckOut: {
    method: "post",
    url: `${baseurl}/api/hotel-room/check-out`,
  },
  // 时长续期
  timeRenewal: {
    method: "post",
    url: `${baseurl}/api/hotel-room/change-end-time`,
  },
  // 客房月账单
  hotelMonthBill: {
    method: "get",
    url: `${baseurl}/api/hotel-room/bill/month`,
  },
  // 客房月账单详情总计
  hotelMonthBillDetail: {
    type: "restful",
    method: "get",
    url: `${baseurl}/api/hotel-room/bill/month/{id}`,
  },
  // 客房月账单详情明细列表
  hotelMonthBillDetailList: {
    method: "get",
    url: `${baseurl}/api/hotel-room/bill/month/detail`,
  },
  // 客房日账单明细列表
  hotelDayBillList: {
    method: "get",
    url: `${baseurl}/api/hotel-room/bill`,
  },
  // 客房日账单总计
  hotelDaySum: {
    method: "get",
    url: `${baseurl}/api/hotel-room/bill/total`,
  },
  // 酒店子账户列表
  hotelSubUser: {
    method: "get",
    url: `${baseurl}/api/subUser`,
  },
  // 添加子账户
  addSubUser: {
    method: "post",
    url: `${baseurl}/api/subUser`,
  },
   // 删除子账户
   delSubUser: {
    type: "restful",
    method: "delete",
    url: `${baseurl}/api/subUser/{subUserId}`,
  },
  // 重置子账户密码
  resetSubUserPwd: {
    method: 'put',
    url: `${baseurl}/api/subUser/resetPwd`,
  },
  // 修改子账户角色
  editSubUserRole: {
    method: 'put',
    url: `${baseurl}/api/zimoyunRoleUser`,
  },
}