/*
 * @Author: fansolc
 * @Date: 2021-04-25 10:37:38
 * @LastEditors: fansolc
 * @LastEditTime: 2021-08-11 16:40:08
 * @Description:
 */
const baseurl = process.env.VUE_APP_API_URL;

export default {
  // 登录
  login: {
    method: "post",
    url: `${baseurl}/api/user/login`,
  },
  // 修改密码
  modifyPassword: {
    method: "put",
    url: `${baseurl}/api/account`,
  },
  // 获取验证码
  sendCode: {
    type: "restful",
    method: "get",
    url: `${baseurl}/api/auth/verify-code/{userName}`,
    responseType: 'blob'
  },
  // 退出登录
  logout: {
    method: "post",
    url: `${baseurl}/api/user/logout`,
  },
  // 用户信息
  getUserInfo: {
    method: "get",
    url: `${baseurl}/api/account`,
  },
  // 客户反馈
  feedback: {
    method: "post",
    url: `${baseurl}/api/feedback`,
  },
  // 充值记录
  rechargeRecord: {
    method: "get",
    url: `${baseurl}/api/recharge`,
  },
  // 消费记录
  consumeRecord: {
    method: "get",
    url: `${baseurl}/api/consumeReport`,
  },
  // 支持的支付方式
  getPayType: {
    method: "get",
    url: `${baseurl}/api/recharge/type`,
  },
  // 生成订单
  generateOrder: {
    method: "post",
    url: `${baseurl}/api/recharge`,
  },
  // 查询订单状态
  lookOrderStatus: {
    type: "restful",
    method: "get",
    url: `${baseurl}/api/recharge/{orderNo}`,
  },
  // 统计特定时间段消费总额
  totalMoneyByDuration: {
    method: "get",
    url: `${baseurl}/api/consumeReport/sum`,
  },
};
