/*
 * @Author: fansolc
 * @Date: 2021-07-23 11:13:04
 * @LastEditors: fansolc
 * @LastEditTime: 2021-07-23 13:19:38
 * @Description: 
 */
const baseurl = process.env.VUE_APP_API_URL 

export default {
  // 网吧列表
  getAllBar: {
    method: "get",
    url: `${baseurl}/api/internet-bar`
  },
  // 网吧费率
  getBarRate: {
    type: "restful",
    method: "get",
    url: `${baseurl}/api/rate/{internetBarId}`
  }
}