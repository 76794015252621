/*
 * @Author: fansolc
 * @Date: 2021-07-23 11:13:04
 * @LastEditors: fansolc
 * @LastEditTime: 2021-07-27 16:49:54
 * @Description: 
 */
const baseurl = process.env.VUE_APP_API_URL 

export default {
  // 设备列表
  getAllEquipment: {
    method: "get",
    url: `${baseurl}/api/box`
  },
  // 修改位置信息
  modifyPosition: {
    method: "put",
    url: `${baseurl}/api/box`
  }
}