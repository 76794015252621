const baseurl = process.env.VUE_APP_API_URL;

export default {
  // 获取时长卡列表
  getTimeFlowCard: {
    method: "get",
    url: `${baseurl}/api/timeFlowCard`,
  },
  // 购买时长卡
  buyTimeFlowCard: {
    method: "post",
    url: `${baseurl}/api/timeFlowCard`,
  },
  // 我的时长卡列表
  getMyTimeFlowCard: {
    method: "get",
    url: `${baseurl}/api/timeFlowCardUser`,
  },
  // 修改自动续费
  changeCardState: {
    method: "put",
    url: `${baseurl}/api/timeFlowCardUser`,
  },
  // 查询当前网吧是否处在免费期
  checkFreeCost: {
    method: "get",
    url: `${baseurl}/api/rate/checkFreeCost`,
  },
  // 更换时长卡列表
  replaceList: {
    method: "get",
    url: `${baseurl}/api/timeFlowCard/card/list`,
  },
  // 时长卡购买记录
  buyCardList: {
    method: "get",
    url: `${baseurl}/api/timeFlowCardUser/card/records`,
  },
  // 更换月卡
  replaceCard: {
    method: "post",
    url: `${baseurl}/api/timeFlowCardUser/card/change`,
  },
  // 消息通知
  notifyMessage: {
    method: "put",
    type: "restful",
    url: `${baseurl}/api/notifyMessage/checkLast/{eventCode}`,
  },
  // 用户时长卡使用记录
  useRecords: {
    method: "get",
    url: `${baseurl}/api/timeFlowCardUser/service/records`,
  },
  // 获取当前网吧盒子数
  quantityBox: {
    method: "get",
    url: `${baseurl}/api/box/quantityBox`,
  },
  // 获取当前网吧有效盒子数
  validQuantityBox: {
    method: "get",
    url: `${baseurl}/api/box/valid-quantity-box`,
  },
  // 根据月卡ID计算订单价格详情
  orderPrice: {
    method: "get",
    url: `${baseurl}/api/timeFlowCard/card/count`
  },
  // 月卡升级列表
  upgrades: {
    method: "get",
    url: `${baseurl}/api/timeFlowCard/card/upgrade/list`,
  },
  // 升级计算差值价格
  betweenPrice: {
    method: "get",
    url: `${baseurl}/api/timeFlowCardUser/betweenPrice`,
  },
  // 升级月卡
  upgradeCard: {
    method: "post",
    url: `${baseurl}/api/timeFlowCardUser/userCard/upgrade`,
  },
  // 查询当前用户未生效的月卡
  expiredCard: {
    method: "get",
    url: `${baseurl}/api/timeFlowCardUser/queryExpiredCard`,
  },
  // 更换次数
  changeCount: {
    method: "get",
    url: `${baseurl}/api//timeFlowCardUser/countChange`,
  },
};
