/*
 * @Author: fansolc
 * @Date: 2021-04-25 10:34:47
 * @LastEditors: fansolc
 * @LastEditTime: 2021-07-23 11:33:57
 * @Description: 
 */
import user from './module/user'
import dataStatic from './module/dataStatic'
import netbar from './module/netbar'
import equipment from './module/equipment'
import durationCard from './module/durationCard'
import homeChart from './module/homeChart'
import hotel from './module/hotel'

export default {
  ...user,
  ...dataStatic,
  ...netbar,
  ...equipment,
  ...durationCard,
  ...homeChart,
  ...hotel
}