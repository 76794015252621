/*
 * @Author: fansolc
 * @Date: 2020-07-22 10:18:25
 * @LastEditors: fansolc
 * @LastEditTime: 2021-08-11 09:56:50
 * @Description:
 */

import axios from "axios";
import api from "./api";
import { ElMessage } from "element-plus";
import store from "@/store";

const service = api;
const instance = axios.create({
  // baseURL: "/api",
  timeout: 1000 * 60,
});

const Http: any = {}; // 包裹请求方法的容器

//restful路径参数替换
const toRest = (url, params) => {
  const reg = new RegExp("\\{(.+?)\\}", "gi");
  const paramArr = url.match(reg);
  paramArr.forEach((el) => {
    const key = el.substring(1, el.length - 1);
    url = url.replace(el, params[key]);
  });
  return url;
};

// 请求格式/参数的统一
for (const key in service) {
  // async 作用：避免进入回调地狱
  Http[key] = async function(
    params, // 请求参数 get：url; put，post，patch（data）; delete：url
    isFormData = false, // 标识是否是form-data请求
    config: any = {} // 配置参数
  ) {
    const api = { ...service[key] }; // url method
    let newParams: any = {};
    //  content-type是否是form-data的判断
    if (params && isFormData) {
      newParams = new FormData();
      for (const i in params) {
        newParams.append(i, params[i]);
      }
    } else {
      newParams = params;
    }
    // 判断是否有restful
    if (api.type === "restful") {
      api.url = toRest(api.url, newParams);
    }
    if (api.responseType === "blob") {
      config.responseType = "blob";
    }

    // 不同请求的判断
    let response = {}; // 请求的返回值
    if (
      api.method === "put" ||
      api.method === "post" ||
      api.method === "patch"
    ) {
      try {
        response = await instance[api.method](api.url, newParams, config);
      } catch (err) {
        response = Promise.reject(err);
      }
    } else if (api.method === "delete" || api.method === "get") {
      if (api.type !== "restful") {
        config.params = newParams;
      }
      try {
        response = await instance[api.method](api.url, config);
      } catch (err) {
        response = Promise.reject(err);
      }
    }
    return response; // 返回响应值
  };
}

// 添加请求拦截器
instance.interceptors.request.use(
  function(config) {
    config.headers["ZMY-Authorization"] = sessionStorage.token;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function(response) {
    if (
      (response.data && response.data.status === 200) ||
      response.config.responseType === "blob"
    ) {
      return response.data;
    } else if (response.data.status === 401) {
      ElMessage({
        type: "error",
        message: "token失效，请重新登录",
        duration: 1500,
        onClose: () => {
          store.commit("LOG_OUT")
          location.reload();
        },
      });
      return Promise.reject(response.data);
    } else {
      ElMessage.error(response.data.msg || "出错了～");
      return Promise.reject(response.data);
    }
  },
  function(error) {
    if (error.response.status === 401) {
      ElMessage({
        type: "error",
        message: "token失效，请重新登录",
        duration: 1500,
        onClose: () => {
          store.dispatch("LOG_OUT").then(() => {
            location.reload();
          });
        },
      });
    } else {
      ElMessage.error("服务器故障");
    }

    return Promise.reject(error);
  }
);

export default Http;
