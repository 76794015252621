/*
 * @Author: fansolc
 * @Date: 2021-07-22 14:58:55
 * @LastEditors: fansolc
 * @LastEditTime: 2021-07-23 13:53:21
 * @Description:
 */
const baseurl = process.env.VUE_APP_API_URL;

export default {

  // 消费明细列表
  getUsageRecord: {
    method: "get",
    url: `${baseurl}/api/report/box-service-record`,
  },
  // 消费明细列表
  dataDetail: {
    method: "get",
    url: `${baseurl}/api/report`,
  },
  // 月消费明细
  monthDataDetail: {
    method: "get",
    url: `${baseurl}/api/report/internetBarServiceList`,
  },
  // 费用账单列表
  billsDetail: {
    method: "get",
    url: `${baseurl}/api/bill`,
  },
  // 计时收费统计
  timingSum: {
    method: "get",
    url: `${baseurl}/api/report/box-service/sum`,
  },
  // 申请发票账单列表
  reckoningList: {
    method: "get",
    url: `${baseurl}/api/report/reckoningList`,
  },
  // 发票信息列表
  invoiceInfoList: {
    method: "get",
    url: `${baseurl}/api/invoiceMessage`,
  },
  // 发票信息新增
  invoiceInfoAdd: {
    method: "post",
    url: `${baseurl}/api/invoiceMessage`,
  },
  // 发票信息更新
  invoiceInfoEdit: {
    method: "put",
    url: `${baseurl}/api/invoiceMessage`,
  },
  // 发票地址列表
  invoiceAddressList: {
    method: "get",
    url: `${baseurl}/api/address`,
  },
  // 发票地址新增
  invoiceAddressAdd: {
    method: "post",
    url: `${baseurl}/api/address`,
  },
  // 发票地址更新
  invoiceAddressEdit: {
    method: "put",
    url: `${baseurl}/api/address`,
  },
  // 行政区省级
  getProvince: {
    method:'get',
    url:`${baseurl}/api/region/province`
  },
  // 行政区市级
  getCity: {
    type: "restful",
    method:'get',
    url:`${baseurl}/api/region/city/{provinceId}`
  },
   // 行政区区
   getDistrict: {
    type: "restful",
    method:'get',
    url:`${baseurl}/api/region/district/{cityId}`
  },
  // 开票记录列表
  getInvoiceLog: {
    method:'get',
    url:`${baseurl}/api/invoice`
  },
  // 撤销开票
  cancelInvoice: {
    type: "restful",
    method:'put',
    url:`${baseurl}/api/invoice/{id}`
  },
  // 开票详情
  getInvoiceDetail: {
    type: "restful",
    method:'get',
    url:`${baseurl}/api/invoice/{invoiceId}`
  },
   // 修改邮箱
   editEmail: {
    method:'put',
    url:`${baseurl}/api/account/email`
  },
   // 删除地址
   delAddress: {
    type: "restful",
    method:'put',
    url:`${baseurl}/api/address/{addressId}`
  },
  // 申请发票
  applyInvoice: {
    method:'post',
    url:`${baseurl}/api/invoice`
  },
   // 开票金额详情
   invoiceReckoning: {
    method:'get',
    url:`${baseurl}/api/invoice/reckoningList`
  }
};
