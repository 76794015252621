const baseurl = process.env.VUE_APP_API_URL;
export default {
  // 云电脑数量等信息
  boxNumInfo: {
    method: "get",
    url: `${baseurl}/api/statistic/box`,
  },
  // 月卡时长
  timeCardHours: {
    method: "get",
    url: `${baseurl}/api/statistic/timeFolowCard`,
  },
  // 近7天云盒使用次数
  boxUsageNum: {
    method: "get",
    url: `${baseurl}/api/statistic/usage/num`,
  },
  // 近7天云盒使用时长
  boxUsageTime: {
    method: "get",
    url: `${baseurl}/api/statistic/usage/time`,
  }
}